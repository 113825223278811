<template>
  <div>
    <v-container fluid>
      <h1 class="titulo">LANÇAMENTOS</h1>

      <v-row style="justify-content: center;">
        <v-card elevation="5" width="1250">
          <v-card-text>
            <v-container>
              <OrganizzeAddAnyButton
                :title="'Novo lançamento'"
                :menuOptions="recordsTypesEnum"
                @onSelectMenuOption="onSelectMenuOption"
              />

              <OrganizzeFilter :filterList="filterList" :submit="buscarLancamentos"/>

              <v-skeleton-loader type="table" :loading="skLoader" :transition-group="'fade-transition'">
                <v-row no-gutters style="margin-bottom: 16px; margin-top: 16px;">
                  <!-- <v-col
                    v-for="header in ['Pagto/Emissão', 'Conta', 'Nome', 'Categoria', 'Valor', '']"
                    :key="header"
                    :style="header === '' ? { 'text-align': 'end' } : ''"
                    :md="header === '' ? 2 : (header === 'Pagto/Emissão' ? 2 : 2)">
                    <h3>{{header}}</h3>
                  </v-col> -->
                  <v-col md="2"><h3>Pagto/Emissão</h3></v-col>
                  <v-col md="2"><h3>Conta</h3></v-col>
                  <v-col md="3"><h3>Nome</h3></v-col>
                  <v-col md="2"><h3>Categoria</h3></v-col>
                  <v-col md="2" class="text-right"><h3>Valor</h3></v-col>
                  <v-col md="1"></v-col>
                </v-row>

                <h2
                  v-if="recordList.length === 0"
                  style="text-align: center; margin: 32px 0px 32px; color: grey">
                  Nenhum lançamento encontrado!
                </h2>

                <template v-for="record in recordList">
                  <div :key="record.id">
                    <v-divider style="margin-bottom: 8px; margin-top: 4px;"/>

                    <v-row
                      :style="record.dtPaid === null ? { 'background-color': variables.colorBlueBaby } : ''"
                      no-gutters>

                      <v-col md="2">
                        <h4 :style="{ color: variables.colorAccent}">{{(record.dtPaid || record.dtEmission) | datas('DD/MM/YYYY')}}</h4>
                      </v-col>

                      <v-col md="2">
                        <!-- <v-row no-gutters> -->
                          <h4 :style="{ color: variables.colorAccent}">
                            {{getAccount(record)}}
                          </h4>
                        <!-- </v-row> -->
                      </v-col>

                      <v-col md="3">
                        <v-row no-gutters>
                          <!-- <v-icon
                            :color="record.type === 'T' ? '': (record.type === 'R' ? 'green' : 'red')">
                            {{record.type === 'T' ? 'mdi-swap-horizontal': (record.type === 'R' ? 'mdi-arrow-right-thick' : 'mdi-arrow-left-thick')}}
                          </v-icon> -->
                          <v-avatar
                            style="margin-right: 16px;"
                            size="16"
                            :color="record.type === 'R' ? 'green' : 'red'"
                            v-if="record.type !== 'T'" 
                          />

                          <v-icon
                            v-else>
                            {{ 'mdi-swap-horizontal' }}
                          </v-icon>
                          <h4 :style="{ color: variables.colorAccent}">{{record.description}}</h4>
                        </v-row>
                      </v-col>

                      <v-col md="2">
                        <v-row no-gutters>
                          
                          <h4 :style="{ color: variables.colorAccent}">{{(record.RecordCategories || {}).name}}</h4>
                        </v-row>
                      </v-col>

                      <v-col md="2" class="text-right">
                        <!-- <v-row no-gutters> -->
                          <h4 :style="{ color: record.type === 'T' ? variables.colorAccent : (record.type === 'R' ? 'green' : 'red')}">
                            {{record.totalAmount | currency}}
                          </h4>
                        <!-- </v-row> -->
                      </v-col>

                      <v-col md="1">
                        <v-row no-gutters style="justify-content: end;">
                          <OrganizzeReactionMenu
                            :title="record.dtPaid ? 'Efetivado' : 'Pendente'"
                            :liked="!record.dtPaid"
                            :disable="record.type === 'T'"
                            @onLike="pendurarPagamento(record)"
                            @onUnlike="openModalEfetivarPagamento(record)"
                          />

                          <OrganizzeActionButton
                            v-if="canChange(record)"
                            :canEdit="permiteAcao($route, 'edit')"
                            :canDelete="permiteAcao($route, 'delete')"
                            :canRecibo="!!record.dtPaid"
                            @onEdit="editModalCadastro(record)"
                            @onDelete="openModalExcluir(record)"
                            @onEmitirRecibo="openModalEmitirRecibo(record)"
                          />

                          <!-- <OrganizzeActionButton
                            v-else
                            :canEdit="false"
                            :canDelete="false"
                            @onEdit="editModalCadastro(record)"
                            @onDelete="openModalExcluir(record)"/> -->
                        </v-row>
                      </v-col>
                    </v-row>
                  </div>
                </template>

                <v-divider style="margin-bottom: 8px; margin-top: 4px;"/>

                <v-row no-gutters>
                  <v-spacer/>

                  <v-col>
                    <v-row no-gutters>
                      <v-col>
                        <h4 :style="{ color: variables.colorAccent}">Saldo anterior:</h4>
                        <h4 v-if="showValuesDetails" :style="{ color: variables.colorAccent}">Entradas efetivadas:</h4>
                        <h4 v-if="showValuesDetails" :style="{ color: variables.colorAccent}">Entradas pendentes:</h4>
                        <h4 v-if="showValuesDetails" :style="{ color: variables.colorAccent}">Despesas efetivadas:</h4>
                        <h4 v-if="showValuesDetails" :style="{ color: variables.colorAccent}">Despesas pendentes:</h4>
                        <h4 v-if="showValuesDetails" :style="{ color: variables.colorAccent}">Custeio efetivadas:</h4>
                        <h4 v-if="showValuesDetails" :style="{ color: variables.colorAccent}">Custeio pendentes:</h4>
                        <h4 v-if="showValuesDetails" :style="{ color: variables.colorAccent}">Saldo final:</h4>
                        <h4 :style="{ color: variables.colorAccent}">Saldo previsto:</h4>
                      </v-col>

                      <v-col style="text-align: end;">
                        <h4 :style="{ color: variables.colorAccent}">{{balanceList.initialBalance | currency}}</h4>
                        <h4 v-if="showValuesDetails" :style="{ color: variables.colorAccent}">{{balanceList.incomingsEfective | currency}}</h4>
                        <h4 v-if="showValuesDetails" :style="{ color: variables.colorAccent}">{{balanceList.incomingsPending | currency}}</h4>
                        <h4 v-if="showValuesDetails" :style="{ color: variables.colorAccent}">{{balanceList.outgoingsEfective | currency}}</h4>
                        <h4 v-if="showValuesDetails" :style="{ color: variables.colorAccent}">{{balanceList.outgoingsPending | currency}}</h4>
                        <h4 v-if="showValuesDetails" :style="{ color: variables.colorAccent}">{{balanceList.costingEfective | currency}}</h4>
                        <h4 v-if="showValuesDetails" :style="{ color: variables.colorAccent}">{{balanceList.costingPending | currency}}</h4>
                        <h4 v-if="showValuesDetails" :style="{ color: variables.colorAccent}">{{balanceList.finalBalance | currency}}</h4>
                        <h4 :style="{ color: variables.colorAccent}">{{balanceList.foreseenBalance | currency}}</h4>
                      </v-col>

                      <button
                        class="showValuesDetailsButtom"
                        @click="showValuesDetails = !showValuesDetails">
                        <h1
                          style="align-self: center;">
                          {{showValuesDetails ? '-' : '+'}}
                        </h1>
                      </button>
                    </v-row>
                  </v-col>
                </v-row>
              </v-skeleton-loader>
            </v-container>
          </v-card-text>
        </v-card>
      </v-row>
    </v-container>
    <ModalCadastro @onEmitirRecibo="openModalEmitirRecibo" />
    <ModalExcluir/>
    <ModalEfetivarPagamento/>
    <ModalRecibo/>
  </div>
</template>

<script>
import { recordsTypesEnum } from '@/utils/tempUtils'
import Events from '@/core/service/events'
import { mapActions, mapGetters } from 'vuex'
import { errorSnackbar, successSnackbar } from '@/core/service/utils'
import variables from '@/assets/styles/helpers/_variables.scss'
import { incRequestCounter, REQUEST_KEY_ALL, REQUEST_KEY_ORGANIZZE_FILTER } from '@/utils/miscellaneous'
import { flatten } from 'lodash'
import moment from 'moment'

const namespaceStore = 'organizzeFinanceiroLancamentos'

export default {
  name: 'OrganizzeFinanceiroLancamentos',
  props: {},
  directives: {},
  components: {
    OrganizzeAddAnyButton: () => import('@/views/components/organizze/addAnyButton'),
    ModalCadastro: () => import('./components/modalCadastro'),
    OrganizzeActionButton: () => import('@/views/components/organizze/actionButton'),
    ModalExcluir: () => import('./components/modalExcluir'),
    OrganizzeReactionMenu: () => import('@/views/components/organizze/reactionMenu'),
    ModalEfetivarPagamento: () => import('./components/modalEfetivarPagamento'),
    OrganizzeFilter: () => import('@/views/components/organizze/filter'),
    ModalRecibo: () => import('@/views/organizze/relatorios/categorias/components/modalRecibo'),
  },
  data: () => ({
    skLoader: false,
    showValuesDetails: false,
    filterList: {}
  }),
  created () {},
  async mounted () {
    const self = this

    let initalFilter = {
      _addlFilter: {
        "Records:dtOrder_>=": moment().format('YYYY-MM-DD') + ' 00:00:00',
        "Records:dtOrder_<=": moment().format('YYYY-MM-DD') + ' 23:59:59',
        ...(self.$route.params.initFilterParams || {}).otherFilter
      }
    }
    console.log(initalFilter)
    if (this.$route.params['Records:dtEmission_>=']) {
      initalFilter._addlFilter = this.$route.params
      initalFilter._filter = {}
      console.log('depois', initalFilter)
    }


    self.buscarContas()
    self.buscarCategorias()
    self.buscarTags()
    self.buscarCentroCustos()
    self.buscarContatos()
    
    Events.$on('index::getRecords', async () => {
      self.buscarTags()
      self.buscarLancamentos(initalFilter)
    })

    if (self.$route.params.type) {
      Events.$emit('modalCadastro::open', { recordType: self.recordsTypesEnum.find(it => it.type === self.$route.params.type) })
    }

    if (self.$route.params.typeLancamentos) {

      // buscar todos os lancamentos pendentes desde do começo e 7 dias a frente
      const _addlfilter = {
        "Records:dtOrder_>=": moment('2021-01-01', 'YYYY-MM-DD').format('YYYY-MM-DD') + ' 00:00:00',
        "Records:dtOrder_<=": moment().add(7, 'days').format('YYYY-MM-DD') + ' 23:59:59',
        "Records:type_=": self.$route.params.typeLancamentos,
        "Records:status_=": 'P'
      }

      initalFilter._addlFilter = _addlfilter
      initalFilter._filter = {}

      if (self.$route.params.id) {
        self.getRecordId({ _addlFilter: { 'Records:id_=': parseInt(self.$route.params.id) } }).then(record => {
          Events.$emit('modalCadastro::edit', { record: record[0] })
          self.buscarLancamentos(initalFilter)
        })
      } else {
        self.buscarLancamentos(initalFilter)
      }
    } else {
      self.buscarLancamentos(initalFilter)
    }

    if (self.$route.params.id) {
      self.getRecordId({ _addlFilter: { 'Records:id_=': parseInt(self.$route.params.id) } }).then(record => {
         Events.$emit('modalCadastro::edit', { record: record[0] })
      })
    } 

    
  },
  computed: {
    ...mapGetters(namespaceStore, ['accountList', 'recordList', 'balanceList', 'expenditureCategoryList', 'incomeCategoryList', 'otherCategoryList', 'tagList']),
    ...mapGetters('roles', ['permiteAcao']),
    recordsTypesEnum: recordsTypesEnum,
    variables: () => variables
  },
  methods: {
    ...mapActions(namespaceStore, ['getAccounts', 'getCategories', 'getRecords', 'getTags', 'getCostCenters', 'submit', 'getBalance', 'getContacts', 'getRecordId']),

    onSelectMenuOption (val) { Events.$emit('modalCadastro::open', { recordType: val }) },
    editModalCadastro (data) { Events.$emit('modalCadastro::edit', { record: data }) },
    openModalExcluir (data) { Events.$emit('modalExcluir::open', { record: data }) },
    openModalEfetivarPagamento (data) { 
      if (!(data.RecordCategories && data.RecordCategories.jsonFlags && data.RecordCategories.jsonFlags.InportOnly)) {
        Events.$emit('modalEfetivarPagamento::open', { record: data }) 
      }
    },

    openModalEmitirRecibo (data) { 
      const record = { record: data }
      if (record.record.Contacts) {
        Events.$emit('modalCadastro::recibo', record)
      } else {
        Events.$emit('modalCadastro::edit', record, 'recibo')
      }
    },

    async buscarContas () {
      const self = this

      self.skLoader = incRequestCounter(REQUEST_KEY_ALL, 1)
      incRequestCounter(REQUEST_KEY_ORGANIZZE_FILTER, 1)

      await self.getAccounts().then(() => {
        self.createFilterList(incRequestCounter(REQUEST_KEY_ORGANIZZE_FILTER, -1))
      }).catch(e => {
        console.error(e)
        errorSnackbar(e)
      }).finally(() => {
        self.skLoader = incRequestCounter(REQUEST_KEY_ALL, -1)
      })
    },

    async buscarCategorias () {
      const self = this

      self.skLoader = incRequestCounter(REQUEST_KEY_ALL, 1)
      incRequestCounter(REQUEST_KEY_ORGANIZZE_FILTER, 1)

      await self.getCategories().then(() => {
        self.createFilterList(incRequestCounter(REQUEST_KEY_ORGANIZZE_FILTER, -1))
      }).catch(e => {
        console.error(e)
        errorSnackbar(e)
      }).finally(() => {
        self.skLoader = incRequestCounter(REQUEST_KEY_ALL, -1)
      })
    },

    async buscarTags () {
      const self = this

      self.skLoader = incRequestCounter(REQUEST_KEY_ALL, 1)
      incRequestCounter(REQUEST_KEY_ORGANIZZE_FILTER, 1)

      await self.getTags().then(() => {
        self.createFilterList(incRequestCounter(REQUEST_KEY_ORGANIZZE_FILTER, -1))
      }).catch(e => {
        console.error(e)
        errorSnackbar(e)
      }).finally(() => {
        self.skLoader = incRequestCounter(REQUEST_KEY_ALL, -1)
      })
    },

    async buscarLancamentos (filter) {
      const self = this
      const data = {
        _addlFilter: {
          ...(filter || {})._addlFilter
        },
        _filter: {
          ...(filter || {})._filter
        },

        recordsWithCosting: true,
        noInportOnly: !filter.noInportOnly
      }

      self.skLoader = incRequestCounter(REQUEST_KEY_ALL, 1)
      self.showCalendar = false

      self.buscarSaldos(filter)
      self.getRecords(data).then(() => {}).catch(e => {
        errorSnackbar(e)
        console.error(e)
      }).finally(() => {
        self.skLoader = incRequestCounter(REQUEST_KEY_ALL, -1)
      })
    },

    async buscarCentroCustos () {
      const self = this

      self.skLoader = incRequestCounter(REQUEST_KEY_ALL, 1)

      await self.getCostCenters().then(() => {}).catch(e => {
        console.error(e)
        errorSnackbar(e)
      }).finally(() => {
        self.skLoader = incRequestCounter(REQUEST_KEY_ALL, -1)
      })
    },

    pendurarPagamento (item) {
      if ((item.RecordCategories && item.RecordCategories.jsonFlags && item.RecordCategories.jsonFlags.InportOnly)) {
        return
      }

      const self = this

      item.dtPaid = null
      item.fineValue = 0
      item.interestValue = 0
      item.status = 'P'

      const data = {
        isEdit: true,
        record: item
      }

      self.skLoader = incRequestCounter(REQUEST_KEY_ALL, 1)

      self.submit(data).then(() => {
        successSnackbar('Lançamento editado com sucesso.')
        Events.$emit('OrganizzeFilter::buscar')
      }).catch(e => {
        console.error(e)
        errorSnackbar(e)
       }).finally(() => {
        self.skLoader = incRequestCounter(REQUEST_KEY_ALL, -1)
      })
    },

    async buscarSaldos (filter) {
      const self = this
      const data = {
        _addlFilter: {
          ...filter._addlFilter
        },
        _filter: {
          ...(filter || {})._filter
        },
        noInportOnly: !filter.noInportOnly
      }

      self.skLoader = incRequestCounter(REQUEST_KEY_ALL, 1)

      await self.getBalance(data).then(() => {}).catch(e => {
        console.error(e)
        errorSnackbar(e)
      }).finally(() => {
        self.skLoader = incRequestCounter(REQUEST_KEY_ALL, -1)
      })
    },

    async buscarContatos () {
      const self = this

      self.skLoader = incRequestCounter(REQUEST_KEY_ALL, 1)

      await self.getContacts().then(() => {}).catch(e => {
        console.error(e)
        errorSnackbar(e)
      }).finally(() => {
        self.skLoader = incRequestCounter(REQUEST_KEY_ALL, -1)
      })
    },

    createFilterList (val) {
      if (!val) {
        const self = this
        const categories = flatten([self.expenditureCategoryList, self.incomeCategoryList, self.otherCategoryList])

        self.filterList['Records:type_='] = { 
          title: 'Tipos',
          isMultiple: false,
          items: recordsTypesEnum().map(recordType => ({ title: recordType.name, type: recordType.type }))
        }

        self.filterList['Records:accountId_='] = {
          title: 'Contas',
          isMultiple: false,
          items: self.accountList.map(account => ({ accountId: account.id, title: account.name }))
        }

        self.filterList['Records:recordCategoryId_In'] = {
          title: 'Categorias',
          isMultiple: true,
          // items: categories.map(category => ({ recordCategoryId: category.id, title: category.name }))
          items: this.expenditureCategoryListGrouped(categories)
        }

        self.filterList['RecordTags:tagId_In'] = {
          title: 'Tags',
          isMultiple: true,
          items: self.tagList.map(tag => ({ tagId: tag.id, title: tag.name }))
        }

        Events.$emit('OrganizzeFilter::init', { organizzeFilter: (self.$route.params.initFilterParams || {}).organizzeFilter })
      }
    },

    expenditureCategoryListGrouped (categories) {
      const categoriesParent = categories.filter(item => !item.categoryId)
      const categoriesParentChildren = categoriesParent.flatMap(item => {
        const categoriesChildren = categories.filter(sitem => sitem.categoryId === item.id).flatMap(sitem => ({
              recordCategoryId: sitem.id,
              title: sitem.name,
              categoryId: sitem.categoryId,
              colour: sitem.colour,
              disabled: sitem.jsonFlags && sitem.jsonFlags.InportOnly 
            }))
        console.log( categoriesChildren.reduce((acc, { recordCategoryId }) => acc.concat(recordCategoryId), []).toString())
        return [ { recordCategoryId: categoriesChildren.reduce((acc, { recordCategoryId }) => acc.concat(recordCategoryId), []).toString(), title: item.name, categoryId: item.categoryId, colour: item.colour, disabled: true }, ...categoriesChildren ]
      })
      return categoriesParentChildren
    },

    getAccount (record) {
      if (record.type === 'T') {
        return parseFloat(record.value) < 0 ? record.accountNameTo : record.accountNameFrom
      }

      return record.accountNameFrom
    },

    canChange (record) {
      return !(record.RecordCategories && record.RecordCategories.jsonFlags && record.RecordCategories.jsonFlags.InportOnly)
    }
  },
  validations () { return {} }
}
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/helpers/_variables.scss';

  .v-text-field {
    display: flex;
    width: 50px;
  }

  .showValuesDetailsButtom {
    margin-left: 16px;
    font-size: 20px;

    &:hover {
      color: $colorPrimary;
    }
  }
</style>